/* eslint-disable max-lines */
import { useRef } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useHasMounted, useIsMobile } from '@utils/hooks';
import {
	About,
	BaseFrameShapeColor,
	Button,
	Checklist,
	ClockIcon,
	Detail,
	Flex,
	getThreshold,
	InformationIcon,
	KlarnaPlacement,
	Modal,
	ValuePropBullet,
} from '@components';
import { BASE_FRAME_NAMES, LOCALE_CODES, LOCALE_DICT, MEDIUM_WIDTH, PRODUCT_TYPES } from '@constants';
import { NormalizedCopyGroupFields, NormalizedProduct, NormalizedVariant, VariantControl } from '@ts/product';
import { useSiteSettings } from '@services/contentful';
import { BaseFrameStateDispatch } from '@utils/hooks/useBaseFrame';
import { CopyGroupFields, frameSwatchesProps } from '@ts/index';
import { fetchContentful } from '@services/contentful/client';
import { normalizeContentfulEntry } from '@utils/normalizers/normalize-contentful';
import { BADGE_TYPES, formatCurrency, LENS_COLORS, SUBSCRIPTION_CHECK_LIST } from '@utils/index';
import DropHint from '@components/shopify/DropHint';
import BundleUpsell from '../BundleUpsell';
import FloatingCTA from './FloatingCTA';
import FloatingCTANew from './FloatingCTA_New';
import styles from './ProductViewCTA.module.scss';

type ProductViewCTAProps = {
	buttonProps: Parameters<typeof Button>[0] & {
		hasSubtotal?: boolean;
		isLoading?: boolean;
		availableForSale?: boolean;
		onClick?: (ref?: React.MutableRefObject<HTMLButtonElement | null>) => void;
	};
	product: NormalizedProduct;
	productType: (typeof PRODUCT_TYPES)[keyof typeof PRODUCT_TYPES];
	name: string;
	selectedVariant?: NormalizedVariant;
	subtotal: number;
	setModalType?: (modalType: 'VTO' | 'Imagery') => void;
	primaryController: VariantControl & { buttonProps?: Parameters<typeof Button>[0] };
	aboutProps: Parameters<typeof About>[0];
	updateFrameShape?: (f: (typeof BASE_FRAME_NAMES)[number]) => void;
	frameShape?: (typeof BASE_FRAME_NAMES)[number];
	variantsAvailable?: Set<string>;
	colorController?: VariantControl;
	VTODispatch?: React.Dispatch<BaseFrameStateDispatch>;
	isSunGlasses?: boolean;
	lensColor: LENS_COLORS;
	secondaryButtonProps?: Parameters<typeof Button>[0];
	tag?: typeof BADGE_TYPES.TOP_RATED;
	showFloatingCTAAlways?: boolean;
	showNewSticky?: boolean;
	collectionController?: VariantControl & {
		frameSwatches?: frameSwatchesProps;
	};
};

function ProductViewCTA({
	buttonProps,
	product,
	productType,
	name,
	selectedVariant,
	subtotal,
	primaryController,
	frameShape,
	updateFrameShape,
	variantsAvailable,
	colorController,
	VTODispatch,
	isSunGlasses = false,
	lensColor = null,
	secondaryButtonProps,
	tag,
	setModalType,
	showFloatingCTAAlways = false,
	showNewSticky = false,
	collectionController,
}: ProductViewCTAProps) {
	const hasMounted = useHasMounted();
	const { locale } = useRouter();
	const isMobile = useIsMobile({ maxWidth: MEDIUM_WIDTH });
	const ctaRef = useRef<HTMLButtonElement>(null);
	const { data: siteSettings } = useSiteSettings();
	const isKlarnaTestActive = useFeatureIsOn('klarna-prominence');
	const isShareableWishlistTest = useFeatureIsOn('is-shareable-wishlist-test');
	const isVTOonCarouselImage = useFeatureIsOn('is-vto-on-carousel-image');
	const isStickySidebar = useFeatureIsOn('is-sticky-right-rail');
	const isBaseFrame = productType.includes(PRODUCT_TYPES.BASE_FRAME) && showFloatingCTAAlways;
	const isTopFrame = productType.includes(PRODUCT_TYPES.TOP_FRAME);
	const { countryCode, currencyCode } = LOCALE_DICT[locale];
	const threshold = getThreshold(locale, siteSettings) ?? 0;
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
	const isUsOrCa = locale === LOCALE_CODES.CA || locale === LOCALE_CODES.US;
	const formattedThreshold = formatCurrency({ amount: threshold, currencyCode, locale }, showCurr);
	const freeShippingText =
		threshold > 0 ? `Orders over ${formattedThreshold} ship free` : `Free Standard Shipping on All ${countryCode} Orders`;
	const { data: termCopyGroup } = useQuery(['term-copy'], async () => {
		const copyGroup = await fetchContentful<CopyGroupFields>({
			'content_type': 'copyGroup',
			'fields.slug': 'top-pdp-messaging',
			'limit': 1,
			locale,
		});
		return normalizeContentfulEntry<CopyGroupFields>(copyGroup);
	});
	const getIcon = (slug: string) => {
		if (slug.includes('points')) {
			return <InformationIcon width={16} height={16} color={'#4B4849'} />;
		}
		if (slug.includes('shipping')) {
			return <ClockIcon />;
		}
	};

	if (!hasMounted) return null;

	return (
		<>
			<Flex column gap={3}>
				{showNewSticky ? (
					<FloatingCTANew
						name={name}
						buttonProps={buttonProps}
						primaryController={primaryController}
						collectionController={{ ...collectionController, title: name }}
						productType={productType}
						showNewSticky={showNewSticky}
					/>
				) : (
					!isStickySidebar && (
						<FloatingCTA
							ctaRef={isBaseFrame ? null : ctaRef}
							selectedVariant={selectedVariant}
							name={name}
							subtotal={subtotal}
							productType={productType}
							buttonProps={buttonProps}
							tag={tag}
						/>
					)
				)}
				{productType.includes(PRODUCT_TYPES.BASE_FRAME) && !isVTOonCarouselImage && !isSunGlasses && (
					<Modal.Trigger asChild>
						<Button
							color='white'
							fullWidth
							label='Virtual Try-On'
							data-tryon={product.name}
							onClick={() => {
								if (setModalType) {
									setModalType('VTO');
								}
								VTODispatch({
									type: 'product',
									option: {
										product,
										variantIdentifier: selectedVariant?.option,
									},
								});
							}}
						/>
					</Modal.Trigger>
				)}
				<Button
					size={isMobile ? 'medium' : 'large'}
					color='green'
					fullWidth={!isMobile}
					onClick={() => buttonProps.onClick(ctaRef)}
					withChevron={buttonProps.withChevron}
					chevronDirection='right'
					withPrice={buttonProps?.hasSubtotal}
					price={buttonProps.price}
					label={buttonProps.label}
					disabled={!buttonProps.availableForSale || buttonProps.disabled}
					margin={buttonProps?.margin}
					ref={ctaRef}
					{...buttonProps.dataTags}
				/>
			</Flex>
			{!secondaryButtonProps && (
				<Flex justify='center' style={{ margin: '0.8rem 0 2.4rem' }}>
					{!isKlarnaTestActive && <KlarnaPlacement subtotal={buttonProps.price} />}
				</Flex>
			)}
			{secondaryButtonProps && (
				<Flex column className={styles.subscriptionSection}>
					<Button {...secondaryButtonProps} />
					<Flex justify='center'>{!isKlarnaTestActive && <KlarnaPlacement subtotal={buttonProps.price} />}</Flex>
					<Checklist
						title='All about our Subscription'
						className={styles['subscription-checklist']}
						list={SUBSCRIPTION_CHECK_LIST}
					/>
				</Flex>
			)}
			<BundleUpsell bundleHandles={product?.metafields?.parentBundles} frameShape={frameShape} />
			{isMobile && primaryController.controllerType === 'shape' && (
				<BaseFrameShapeColor
					callback={updateFrameShape}
					primaryController={primaryController}
					variantsAvailable={variantsAvailable}
					colorController={colorController}
					productType={productType}
				/>
			)}
			<Flex gap={3} data-testid='free-shipping-box'>
				{freeShippingText && <ValuePropBullet iconType='truck' description={freeShippingText} />}
				<ValuePropBullet iconType='box' description='Free returns or exchanges' />
				{isTopFrame && <ValuePropBullet iconType='attention' description='Works only with our Base Frames' />}
				{isBaseFrame && isUsOrCa && (
					<ValuePropBullet iconType='shield' description='FSA and HSA Eligible or Insurance Reimbursement' />
				)}
			</Flex>
			{product?.metafields?.isTermCondition && (
				<Flex column gap={4} className={styles.termCopy}>
					{termCopyGroup &&
						(termCopyGroup as NormalizedCopyGroupFields).blocks?.map(({ description, title, slug }, idx) => {
							return (
								<Flex key={`${idx}-${title}`} gap={3} align='start'>
									<Flex>{getIcon(slug)}</Flex>
									<Detail style={{ color: '#4B4849' }}>{description}</Detail>
								</Flex>
							);
						})}
				</Flex>
			)}
			{isUsOrCa && isShareableWishlistTest && !!product?.images?.[0]?.url && (
				<DropHint product={product} variant={selectedVariant} lensColor={isSunGlasses ? lensColor : null} />
			)}
		</>
	);
}

export default ProductViewCTA;
